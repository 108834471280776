<template>
  <v-row>
    <v-col cols="6" xs="3" md="3">
      <h1 class="primary--text">Job Details</h1>
    </v-col>

    <v-col cols="6" xs="3" md="3" offset-md="6" align-self="center">
      <v-btn v-if="is_client"
        small
        rounded
        class="primary float-right"
        to="/overview"
      >
        Back To Job Overview
      </v-btn>
      <v-btn v-else
        small
        rounded
        class="primary float-right"
        :to="prevRoute.name?prevRoute.path:'/review'"
      >
        Back To {{ prevRoute.name ? prevRoute.name : 'review' }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    is_client: {
      type: Boolean,
      default: false
    },
    prevRoute: {
      type: Object,
      default(){
        return {}
      }
    }
  },
}
</script>