<template>
  <v-card>
    <v-card-title class="primary white--text rounded-0">
      <div class="d-flex justify-center align-center mb-4">
        <img src="@/assets/images/logos/logo.png" class="logo" />
      </div>
      <v-spacer></v-spacer>
      <span>{{imgId}}</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="openAnnotorius">
        <v-icon color="white">{{mdiClose}}</v-icon>
      </v-btn>
    </v-card-title>
    
    <v-card-text class="d-flex align-center justify-center">
      <div class="mt-5">
        <img :id="imgId" :src="`${imgSrc}?t=${Math.random()}`" style="width:100%;max-width:1024px;" />
      </div>
    </v-card-text>

    <v-dialog v-model="annoMsgModal" max-width="400">
      <v-card>
        <v-card-text class="pa-3 black--text" style="text-align:justify;">
          <p v-if="annotationMsgItem.length>0" v-for="(item, inx) in annotationMsgItem" :key="inx">
            {{ item.value }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import { Annotorious} from '@recogito/annotorious';
import '@recogito/annotorious/dist/annotorious.min.css';
import SelectorPack from '@recogito/annotorious-selector-pack';
import { mdiClose } from '@mdi/js';


export default {
  props:{
    uuid: { type: String, default: null },
    imgId: { type: String, default: null },
    imgSrc: { type: String, default: null },
    annoLib: { type: Object, default: null },
    annotations: { type: Array, default: () => [] },
    isClient: { type: Boolean, default: false },
  },
  data(props){
    return {
      annoMsgModal: false,
      mdiClose,
      // anno: props.annoLib,
      anno: null,
      annotationMsgItem: [],
      config: {}
    }
  },
  watch: {
    isClient: {
      handler: function(a, b) {
        this.config = {
          allowEmpty: true,
          disableEditor: false,
          readOnly: false,
          image: this.imgId
        }
        if(!a){
          this.config.readOnly = true
          this.config.disableEditor = true
        }
      },
      immediate: true
    }
  },

  methods: {
    openAnnotorius(){
      if(!this.isClient){
        this.anno = null;
      }
      this.$emit('openAnnotorius', this.anno)
    },

    initAnno(event){
      this.anno = new Annotorious(this.config);
      this.anno.setAnnotations(this.annotations);

      SelectorPack(this.anno);
      this.anno.setDrawingTool("point");

      if(!this.isClient){
        this.anno.on('clickAnnotation', function(annotation, element){
          event.getAnnotationMsg(annotation);
        });
      }
    },

    getAnnotationMsg(data){
      this.annoMsgModal = true;
      this.annotationMsgItem = data['body'];
    },
  },

  mounted(){
    this.initAnno(this);
  //   let vm = this;
  //   this.anno = new Annotorious({
  //     image: document.getElementById(this.imgId),
  //   });
  //   this.anno.setDrawingTool('point');

  //   vm.anno.on("updateAnnotation", function (annotation, previous) {
  //     //vm.annotations
  //     //console.log(annotation)
  //     // console.log(vm.anno.getAnnotations());
  //   });
  }
}
</script>

<style lang="sass">
.pa-control
  border: 1px solid #8a8d93
  border-radius: 0.5em
  margin-bottom: 0.5em
</style>