<template>
  <v-row>
    <v-col cols="12" sm="12">
      <back-button :is_client="is_client" :prevRoute="prevRoute"></back-button>
      <!-- loader -->
      <v-skeleton-loader
        v-if="isLoading"
        type="table"
        class="mr-2 mt-6"
      >
      </v-skeleton-loader>
      <v-row v-else class="mt-0">
        <v-col cols="12" xs="12" md="12">
          <v-card>
            <v-card-text>
              <!-- stepper -->
              <v-row>
                <v-col cols="12">
                  <stepper :stepper="stepper" :success="success"/>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <!-- Job details -->
              <v-row>
                <v-col cols="12">
                  <h2 class="primary--text">JOB #{{$tools.addLeadingZeros(details.job_id)}}</h2>
                  <p class="pt-3">Job detail are as follows:</p>
                </v-col>
                <template>
                  <v-col cols="5" md="2" class="pr-0 font-weight-bold">JOB NAME</v-col>
                  <v-col cols="7" md="10">{{details.job_name}}</v-col>
                </template>
                <template>
                  <v-col cols="5" md="2" class="pr-0 font-weight-bold">INSTRUCTIONS</v-col>
                  <v-col cols="7" md="10" class="pl-1">
                    <v-list class="pa-0">
                      <p class="mb-1" v-for="item in instructions" :key="item">
                        <v-icon>{{ icons.mdiCircleMedium }}</v-icon> {{item}}
                      </p>
                    </v-list>
                  </v-col>
                </template>
                <template>
                  <v-col cols="5" md="2" class="pr-0 font-weight-bold">TOTAL</v-col>
                  <v-col cols="7" md="10">{{uploaded}} image(s) uploaded</v-col>
                </template>
                <template>
                  <v-col cols="5" md="2" class="pr-0 font-weight-bold">SIZE OF JOB</v-col>
                  <v-col cols="7" md="10">{{filesize}}</v-col>
                </template>
                <template>
                  <v-col cols="5" md="2" class="pr-0 font-weight-bold">
                    {{is_client ? "YOUR" : "CLIENT"}} BUDGET
                  </v-col>
                  <v-col cols="7" md="10">{{details.buyer_amount==0?'N/A':$tools.floatNum(details.buyer_amount)}}</v-col>
                </template>
                <template v-if="status!='requested'">
                  <v-col cols="5" md="2" class="pr-0 font-weight-bold">REVIEWED</v-col>
                  <v-col cols="7" md="10"><span class="primary--text">{{reviewed}} image(s)</span></v-col>
                </template>
                <template v-if="status=='revision'">
                  <v-col cols="5" md="2" class="pr-0 font-weight-bold">
                    REVISION (<span class="error--text">{{(details.revision-1)}}</span>)
                  </v-col>
                  <v-col cols="7" md="10">
                    <span class="error--text">{{details.file_count}} image(s)</span>
                  </v-col>
                </template>
                <template v-if="status!='requested'">
                  <v-col cols="5" md="2">
                    <span class="float-left font-weight-bold">DELIVER TIME</span>
                  </v-col>
                  <v-col cols="7" md="4">
                    <span class="primary--text">{{$tools.dateTimeToStr(details.production_at, "yyyy-MM-dd hh:mm")}}</span>
                  </v-col>
                </template>
                <!-- show if quotation status will be requested and role will be reviewer -->
                <template v-if="status=='requested' && is_reviewer">
                  <v-col cols="5" md="2">
                    <span class="mt-5 float-left font-weight-bold">AMOUNT</span>
                  </v-col>
                  <v-col cols="7" md="4">
                    <v-text-field
                      v-model="seller_amount"
                      solo
                      type="number"
                      class="pt-0"
                      :rules="[required('AMOUNT')]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5" md="2">
                    <span class="mt-5 float-left font-weight-bold">DELIVER TIME</span>
                  </v-col>
                  <v-col cols="7" md="4">
                    <v-datetime-picker
                      v-model="production_at"
                      label="Possible Deliver Time"
                      :rules="[required('DELIVER TIME')]">
                      <template slot="dateIcon">
                        <v-icon>{{icons.mdiCalendar}}</v-icon>
                      </template>
                      <template slot="timeIcon">
                        <v-icon>{{icons.mdiClockAlertOutline}}</v-icon>
                      </template>
                      <template slot="actions" slot-scope="{ parent }">
                        <v-btn small text rounded color="warning" class="mt-5" @click.native="parent.clearHandler">
                          Cancel
                        </v-btn>
                        <v-btn small rounded color="primary" class="mt-5" @click="parent.okHandler">
                          OK
                        </v-btn>
                      </template>
                    </v-datetime-picker>
                  </v-col>
                </template>
              </v-row>
              <!-- show if quotation status will not be requested && reviewed -->
              <v-row v-if="status!='requested'">
                <v-col cols="12">
                  <div class="headline font-weight-bold mr-5">
                    <span>{{reviewed}} Image(s) invoiced</span>
                    <span class="float-right warning--text">${{$tools.floatNum(details.seller_amount)}}</span>
                  </div>
                </v-col>
                <v-col v-if="is_client && status=='reviewed'" cols="12">
                  <v-btn @click="clickAcceptQuotation" small class="primary rounded-pill px-5 float-right">
                    ACCEPT
                  </v-btn>
                </v-col>
              </v-row>
              <!-- access to upload or view if quotation status will in array -->
              <upload
                v-if="['requested', 'reviewed', 'accepted'].includes(status)"
                :status="status"
                :isReviewer="is_reviewer"
                :isDeliver="is_deliver"
                :isClient="is_client"
                :contents="contents"
                :reviewed="reviewed"
                :method="details.upload_via"
                :path="`${details.job_id}/requested`"
                @getData="setInstructions">
              </upload>
              <!-- Job processing unit -->
              <processing
                v-if="['processing', 'delivered'].includes(step_stat)"
                :step_stat="step_stat"
                :status="status"
                :revision="revision"
                :isProcess="is_process"
                :isDeliver="is_deliver"
                :isClient="is_client"
                :contents="contents"
                :reviewed="reviewed"
                :method="details.upload_via"
                :userId="details.user_id"
                :jobId="details.job_id"
                @setAnnotations="getAnnotations"
                @deliveredItems="setDeliveredItems">
              </processing>
              <!-- complete view -->
              <completed
                v-if="['completed'].includes(status)"
                :status="status"
                :isClient="is_client"
                :contents="contents"
                :jobId="details.job_id"
                @deliveredItems="setDeliveredItems">
              </completed>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions class="text-center d-block">
              <v-btn v-if="is_client && status=='requested'" @click="updateQuotation" :disabled="!isUploaded" class="primary rounded-pill px-5">
                UPDATE QUOTATION
              </v-btn>
              <v-btn v-if="is_reviewer && status=='requested'" @click="clickReviewQuotation" class="primary rounded-pill px-5">
                REVIEW DONE
              </v-btn>
              <v-btn v-if="status!='delivered' && (is_deliver && status=='processing')" @click="clickDeliverQuotation" class="primary rounded-pill px-5">
                Deliver NOW
              </v-btn>
              <v-btn v-if="isAnnotated && is_client && status=='delivered'" @click="clickRevisionQuotation" class="primary rounded-pill px-5">
                Send to revision
              </v-btn>
              <v-btn v-if="is_revision && status=='revision'" :disabled="!revisionDone" @click="clickDeliverQuotation" class="primary rounded-pill px-5">
                revision done
              </v-btn>
              <v-btn v-if="!isAnnotated &&is_client && status=='delivered'" @click="clickCompletedQuotation" class="primary rounded-pill px-5">
                COMPLETED
              </v-btn>
              <v-btn v-if="is_accepted && status=='accepted'" @click="clickProcessQuotation" class="primary rounded-pill px-5">
                Send to process
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <!-- dialog modal -->
    <DialogModal
      v-model="showModal"
      :title="modal_title"
      :message="modal_msg"
      :width="modal_width"
      :confirmButton="confirm_button"
      :headlineClass="`${modal_cls} white--text`"
      @onCancel="showModal = false"
      @onConfirmation="isConfirmModal()"
    />
    <!-- Annotation Details modal -->
    <v-dialog
      v-model="revisionModal"
      scrollable
      max-width="1024px"
    >
      <v-card v-if="isAnnotated">
        <v-card-title class="primary white--text">
          <span>Annotation Details</span><v-spacer></v-spacer>
          <v-icon color="white font-weight-bold" @click="revisionModal=false">{{icons.mdiClose}}</v-icon>
        </v-card-title>
        <v-card-text class="pt-5" style="max-height:700px;">
          <v-row v-for="item, key in annotatedItems" :key="key">
            <v-col cols="12" class="pb-0 text-h5 text-center primary--text">
              {{key}}
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-data-table
                :headers="annotatedHeaders"
                :items="extractAnnotationItems(item)"
                hide-default-footer
                class="elevation-1"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="isSubmit" small text color="error" class="rounded-pill px-3" @click="delAnnotation()">Remove</v-btn>
          <v-btn :loading="isSubmit" small class="primary rounded-pill px-4" @click="isConfirmModal()">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </v-row>
</template>


<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  LOAD_QUOTATION_BY_ID,
  UPDATE_QUOTATION,
  REFRESHTOKEN
} from "@/store/_actiontypes";
import validations from "@/utils/validations";
import { Role } from "@/utils/constant";
import json from "@/assets/static/data.json"
import BackButton from '@/components/common/BackButton';
import DialogModal from "@/components/common/DialogModal";
import Stepper from "@/components/overview/Stepper";
import Upload from "@/components/overview/Upload";
import Processing from "@/components/overview/Processing";
import Completed from "@/components/overview/Completed";
import { mdiCircleMedium, mdiCalendar, mdiClockAlertOutline, mdiClose } from '@mdi/js';

export default {
  components: {
    BackButton,
    DialogModal,
    Stepper,
    Upload,
    Processing,
    Completed,
  },

  data(){
    return {
      ...validations,
      prevRoute: {},
      is_client: this.$tools.authorize([Role.CLIENT]),
      is_reviewer: this.$tools.authorize([Role.REVIEW]),
      is_accepted: this.$tools.authorize([Role.ACCEPTED]),
      is_process: this.$tools.authorize([Role.PROCESSING]),
      is_deliver: this.$tools.authorize([Role.DELIVERED]),
      is_revision: this.$tools.authorize([Role.REVISION]),
      isLoading: true,
      isSubmit: false,
      isUploadMore: false,
      showModal: false,
      confirm_button: true,
      isUploaded: true,
      modal_action: "",
      modal_title: "",
      modal_msg: "",
      modal_width: 650,
      modal_cls: "primary",
      status: "",
      step_stat: "",
      seller_amount: 0,
      production_at: new Date(),
      success: 2,
      contents: {},
      instructions: [],
      update_file_info: [],
      submitedFileInfo: [],
      deliveredItems: [],
      annotatedItems: [],
      revisionedItems: {},
      annotatedHeaders:[
        { text: 'Purpose', value: 'purpose'}, {text: 'Description', value: 'value' }
      ],
      isAnnotated: false,
      revisionModal: false,
      revisionDone: false,
      revision: 0,
      uploaded: 0,
      reviewed: 0,
      filesize: 0,
      stepper: json.overview.stepper,
      ins_checkbox: json.overview.ins_checkbox,
      adv_ins_checkbox: json.overview.adv_ins_checkbox,
      icons: {
        mdiCircleMedium,
        mdiCalendar,
        mdiClockAlertOutline,
        mdiClose
      },
    }
  },

  computed: {
    ...mapState({
      details: (state) => state.quotation.details,
      user: (state) => state.account.user,
    }),
    ...mapGetters("loader", ["loading"]),
  },

  created(){
    this.getDetails();
  },

  methods: {
    ...mapActions("quotation", [LOAD_QUOTATION_BY_ID, UPDATE_QUOTATION]),
    ...mapActions("account", [REFRESHTOKEN]),

    getDetails(){
      if(!this.$route.query.uuid) return;

      this.LOAD_QUOTATION_BY_ID({
        quotation_id: this.$route.query.uuid
      }).then((response) => {
        this.isLoading = false;
        this.setData(response.data);
      }, (error) => {
        this.isLoading = false;
      });
    },

    setData(data){
      this.status = data.status;
      this.step_stat = (data.status=='delivered'||data.status=='revision') ? 'processing': data.status;
      let step_txt = ["requested", "reviewed", "accepted", "processing", "completed"];
      this.success = step_txt.indexOf(this.step_stat) + 2;
      this.revision = data.revision;
      this.seller_amount = data.seller_amount;
      this.contents = this.details.file_info;
      this.uploaded = data.file_info["requested"].length;
      this.instructions = this.getInstructions();
      this.filesize = this.getFileSize();
      this.setDeliveredItems(this.contents);
      this.setReviewedData();
      this.getAnnotations();
    },

    setReviewedData(){
      let reviewedItems = [];
      this.details.file_info["reviewed"].forEach(item => {
        if(item.selected == true){
          reviewedItems.push(item);
        }
      });
      this.reviewed = reviewedItems.length;
    },

    getInstructions(){
      const data = this.details;

      let instructions = [];
      if(data.instructions.hasOwnProperty(0)){
        data.instructions.forEach(id => {
          instructions.push(
            this.getInstruction(json.overview.ins_checkbox, id)
          );
        });
      }
      if(data.adv_instructions.hasOwnProperty(0)){
        data.adv_instructions.forEach(id => {
          instructions.push(
            this.getInstruction(json.overview.adv_ins_checkbox, id)
          );
        });
      }
      if(data.custom_instructions != ""){
        instructions.push(data.custom_instructions);
      }
      return instructions;
    },

    getInstruction(objArr, key){
      var existingObj = objArr.find(function(element) {
        return element["val"] == key ? element : false;
      });
      return existingObj ? existingObj["text"] : null;
    },

    updateQuotation(){
      this.isLoading = true;

      let data = { file_info: this.update_file_info };
      let quotationId = this.details.job_id;
      let type = "requested";

      this.UPDATE_QUOTATION({quotationId, type, data}).then((res) => {
        this.getDetails();
      }).catch((err) => {
        this.isLoading = false;
      });
    },

    // review action start
    clickReviewQuotation(){
      let file_info = [];
      let file_count = 0;
      this.details.file_info.requested.forEach(item => {
        if(item.hasOwnProperty("selected")){
          if(item.selected){
            ++file_count;
            item["selected"] = true;
          } else{
            item["selected"] = false;
          }
        } else{
          item["selected"] = false;
        }
        file_info.push(item);
      });

      let production_at = this.$tools.dateTimeToStr(this.production_at, "yyyy-MM-dd hh:mm");

      this.modal_msg = `Deliver Time: ${production_at} || Amount: $${this.$tools.floatNum(this.seller_amount)} || Review: ${file_count} Image(s)`;
      if(file_count>0 && this.seller_amount != 0 && production_at){
        this.modal_title = "Confirm";
        this.modal_action = "review";
        this.modal_cls = "primary";
        this.submitedFileInfo = file_info;
        this.confirm_button = true;
      } else{
        this.modal_cls = "error";
        this.modal_title = "Error";
        this.confirm_button = false;
      }
      this.showModal = true;
    },
    submitReviewQuotation(){
      this.isLoading = true;
      let utcTime = new Date(this.production_at).toISOString();

      let data = {
        seller_amount: this.seller_amount,
        file_info: this.submitedFileInfo,
        production_at: utcTime
      };
      let quotationId = this.details.job_id;
      let type = "review";

      this.UPDATE_QUOTATION({quotationId, type, data}).then((res) => {
        this.getDetails();
      }).catch((err) => {
        this.isLoading = false;
      });
    },
    // review action end

    // accept action start
    clickAcceptQuotation(){
      let data = this.details.file_info;
      
      this.modal_msg = `Invoiced $${this.$tools.floatNum(this.seller_amount)} for ${this.reviewed} Image(s)`;
      this.submitedFileInfo = data.reviewed;
      this.modal_width = 400;
      this.modal_title = "Confirm";
      this.modal_action = "accept";
      this.showModal = true;
    },
    submitAcceptQuotation(){
      this.isLoading = true;

      let data = { file_info: this.submitedFileInfo };
      let quotationId = this.details.job_id;
      let type = "accepted";

      this.UPDATE_QUOTATION({quotationId, type, data}).then((res) => {
        this.$router.push("/overview");
      }).catch((err) => {
        this.isLoading = false;
      });
    },
    // accept action end

    // process JOB start
    clickProcessQuotation(){
      let job_id = this.$tools.addLeadingZeros(this.details.job_id);
      
      this.modal_msg = `Are you sure to process job #${job_id}`;
      this.modal_width = 400;
      this.modal_title = "Confirm";
      this.modal_action = "process";
      this.showModal = true;
    },
    submitProcessQuotation(){
      this.isLoading = true;
      let data = {};
      let quotationId = this.details.job_id;
      let type = "todo";

      this.UPDATE_QUOTATION({quotationId, type, data}).then((res) => {
        this.$router.push("/processing");
      }).catch((err) => {
        this.isLoading = false;
      });
    },
    // process JOB end

    // deliver action start
    setDeliveredItems(data){
      data = data[this.step_stat];
      let revisionDone = true;
      let deliveredItems = [];
      let revisionedItems = {};
      if(data && data.length > 0){
        for(var i = 0; i < data.length; i++){
          if(data[i].hasOwnProperty("delivered")){
            let revision = data[i]["revision"]
            let delivered = data[i]["delivered"][revision]
            deliveredItems.push(delivered);
            if(delivered['revision'] && revision !== this.revision.toString()){
              revisionDone = false;
              revisionedItems[delivered['name']] = delivered['revision']
            }
          }
        }
      }
      this.revisionDone = revisionDone;
      this.revisionedItems = revisionedItems;
      this.deliveredItems = deliveredItems;
    },
    clickDeliverQuotation(){
      let title = this.status=='revision' ? 'revision' : 'deliver';
      this.modal_msg = `Are you sure to ${title} ${this.deliveredItems.length} Image(s)`;
      if(this.deliveredItems.length > 0){
        this.modal_title = "Confirmation";
        this.modal_action = "delivered";
        this.modal_cls = "primary";
        this.modal_width = 400;
        this.submitedFileInfo = this.deliveredItems;
        this.confirm_button = true;
      } else{
        this.modal_cls = "error";
        this.modal_title = "Error";
        this.confirm_button = false;
      }
      this.showModal = true;
    },
    submitDeliveredQuotation(){
      this.isLoading = true;

      let type = "delivered";
      let quotationId = this.details.job_id;
      let data = { file_info: this.submitedFileInfo };

      this.UPDATE_QUOTATION({quotationId, type, data}).then((res) => {
        this.$router.push("/delivered");
      }).catch((err) => {
        this.isLoading = false;
      });
    },
    // deliver action end

    // complete action start
    clickCompletedQuotation(){
      this.modal_width = 400;
      this.modal_msg = `Are you sure the task is completed?`;
      this.modal_title = "Confirm";
      this.modal_action = "completed";
      this.showModal = true;
    },
    submitCompletedQuotation(){
      this.isLoading = true;

      let type = "completed";
      let quotationId = this.details.job_id;
      let data = {};

      this.UPDATE_QUOTATION({quotationId, type, data}).then((res) => {
        this.$router.push("/overview");
      }).catch((err) => {
        this.isLoading = false;
      });
    },
    // complete action end

    // complete action start
    clickRevisionQuotation(){
      this.modal_action = "revision";
      this.revisionModal = true;
    },
    submitRevisionQuotation(){
      this.isLoading = true;
      this.isSubmit = true;

      let type = "revision";
      let quotationId = this.details.job_id;
      let data = this.annotatedItems;

      this.UPDATE_QUOTATION({quotationId, type, data}).then((res) => {
        localStorage.removeItem(this.$route.query.uuid);
        this.$router.push("/overview");
      }).catch((err) => {
        this.isLoading = false;
        this.isSubmit = false;
      });
    },
    // complete action end

    isConfirmModal(){
      this.showModal = false;
      if(this.modal_action == "review"){
        this.submitReviewQuotation();
      } else if(this.modal_action == "accept"){
        this.submitAcceptQuotation();
      } else if(this.modal_action == "delivered"){
        this.submitDeliveredQuotation();
      } else if(this.modal_action == "completed"){
        this.submitCompletedQuotation();
      } else if(this.modal_action == "revision"){
        this.submitRevisionQuotation();
      } else if(this.modal_action == "process"){
        this.submitProcessQuotation();
      }
    },

    setInstructions(data){
      this.isUploaded = data.isUploaded;
      this.isUploadMore = data.upload_more;
      this.update_file_info = data.file_info;
    },

    getFileSize(){
      const content = this.details.file_info["requested"];

      let size = 0;
      if(content.length > 0){
        for(var i = 0; i < content.length; i++){
          size = size + content[i].size;
        }
      }
      return this.$tools.getFileSize(size);
    },

    // annotation data process
    getAnnotations(){
      this.isAnnotated = false
      if(this.status=='delivered'){
        let data = JSON.parse(localStorage.getItem(this.$route.query.uuid))
        if(data !== null && typeof data === 'object'){
          Object.keys(data).forEach(key => {
            if(data[key].length <= 0){
              delete data[key]
            } else{
              this.isAnnotated = true
            }
          })
          this.annotatedItems = data 
        }
      }
    },
    delAnnotation(){
      localStorage.removeItem(this.$route.query.uuid);
      this.revisionModal = false;
      this.getAnnotations()
    },
    extractAnnotationItems(data){
      let annotationItems = []
      for(let i = 0; i < data.length; i++){
        data[i]['body'].forEach(item => {
          annotationItems.push(item);
        });
      }
      return annotationItems
    },
    // annotation data process

  },
  beforeRouteEnter(to, from, next){
    next(vm => {
      vm.prevRoute = from
    })
  },
}
</script>

<style scoped>
.bg-none{
  background: none !important;
  box-shadow: none !important;
}
.gray-border{
  border:1px solid gray;
}
</style>
