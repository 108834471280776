<template>
  <v-row class="mt-3">
    <!-- Image galary viewer -->
    <v-col v-if="list.length>0" cols="12" sm="12">
      <v-row no-gutters class="gray-border px-2 py-2 mb-1 rounded">
        <!-- download -->
        <!-- <v-col cols="12" class="text-right">
          <v-btn v-if="download_link==null" small class="warning mx-2 rounded-pill px-5" @click="downloadAsZip">
            COMPRESS ALL TO DOWNLOAD
          </v-btn>
          <v-btn v-else :href="download_link" small class="error mx-2 rounded-pill px-5" target="_blank">Download</v-btn>
        </v-col> -->
        <template v-for="(item, index) in historyList">
          <v-col :key="index" cols="6" sm="2" class="pa-2">
            <v-card outlined tile class="pa-0 overflow-hidden">
              <v-card-text class="text--primary text-center pa-0">
                <img
                  class="image-style"
                  :src="`${$tools.getEnv('VUE_APP_FILE_SERVER')}${item.path}/${item.name}`"
                  @click="openGallery(index)"
                />
              </v-card-text>

              <v-card-actions class="action-box d-block pa-1" :style="`${item.instruction?'border-top:2px solid red':''}`">
                <span style="font-size:11px;">{{getImageName(item.name)}}</span>
                <v-icon small class="float-right" color="primary" @click="downloadFile(item)">{{mdiDownload}}</v-icon>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-responsive
            v-if="index === 5"
            :key="`width-${index}`"
            width="100%"
            class="pb-1"
          ></v-responsive>
        </template>
      </v-row>

      <v-pagination
        class="pagination"
        v-model="page"
        :length="pages"
        :showCaption="true"
        @input="updatePage">
      </v-pagination>
    </v-col>
    
    <LightBox
      ref="lightbox"
      :nThumbs="pageSize"
      :media="media"
      :showCaption="true"
      :showLightBox="false"
    />
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { mdiDownload } from '@mdi/js'
import { DOWNLOAD_FROM_URL, DOWNLOAD_AS_ZIP } from "@/store/_actiontypes";
import LightBox from 'vue-image-lightbox'
require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

export default {
  components: {
    LightBox,
  },
  props: {
    isProcess: {
      type: Boolean,
      default: false
    },
    isClient: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: "requested",
    },
    contents: {
      type: Object,
      default(){
        return {}
      }
    },
    jobId: {
      type: Number,
      default: 1,
    },
  },
  data(props){
    return {
      mdiDownload,
      download_link: null,
      page: 1,
			pageSize: 12,
			listCount: 0,
			historyList: [],
      media: [],
      list: this.setContent(props.contents)
    }
  },
	computed: {
		pages(){
			if (this.pageSize == null || this.listCount == null) return 0;
			return Math.ceil(this.listCount / this.pageSize);
		}
	},
	created(){
    this.initPage();
    this.updatePage(this.page);
	},
  methods: {
    ...mapActions("upload", [DOWNLOAD_FROM_URL, DOWNLOAD_AS_ZIP]),

    setContent(contents){
      let data = [];
      if(contents.hasOwnProperty(this.status)){
        data = contents[this.status];
      }
      return data;
    },
		initPage(){
			this.listCount = this.list.length;
			if(this.listCount < this.pageSize){
				this.historyList = this.list;
			} else{
				this.historyList = this.list.slice(0, this.pageSize);
			}
		},
		updatePage(pageIndex){
			this.page = pageIndex;
			let _start = (pageIndex - 1) * this.pageSize;
			let _end = pageIndex * this.pageSize;
			this.historyList = this.list.slice(_start, _end);

      let baseurl = this.$tools.getEnv('VUE_APP_FILE_SERVER');
      let media = [];
      this.historyList.forEach(item => {
        let media_obj = {}
        media_obj["type"] = "image";
        media_obj["caption"] = item.name;
        media_obj["thumb"] = `${baseurl}${item.path}/${item.name}`;
        media_obj["src"] = `${baseurl}${item.path}/${item.name}`;
        media.push(media_obj);
      });
      this.media = media;
		},
    
    openGallery(index){
      this.$refs.lightbox.showImage(index)
    },

    downloadFile(item){
      let path = item.path +"/"+ item.name;
      let title = item.name;
      this.DOWNLOAD_FROM_URL({path}).then((res) => {
        this.$tools.downloadFileFromBase64(res, title)
      }).catch(()=>{});
    },

    downloadAsZip(){
      this.download_link = null;
      this.DOWNLOAD_AS_ZIP({job_id:this.jobId, status:this.status}).then((res) => {
        if(res.status==200){
          let file_server = this.$tools.getEnv('VUE_APP_FILE_SERVER');
          this.download_link = res.data.path!=null ? file_server+res.data.path : null;
        }
      }).catch(()=>{
        this.download_link = null;
      });
    },

    getImageName(name){
      if(this.isClient && (this.status=='requested'||this.status=='created')){
        name = name.substring(0, 9) + "..";
      } else if(name.length <= 15){
        name = name
      } else{
        name = name.substring(0, 13) + "..";
      }
      return name;
    },

  }
}
</script>

<style scoped>
.gray-border{
  border:1px solid gray;
}
.action-box{
  height: 25px;
  font-size: 12px;
  background: #fafafa;
}
.image-style{
  width:143px;
  height:143px;
  margin-bottom:-5px;
}
.image-style:hover{
  cursor: pointer;
}
</style>