<template>
  <v-card>
    <v-card-title class="primary white--text rounded-0">
      <div class="d-flex justify-center align-center mb-4">
        <img src="@/assets/images/logos/logo.png" class="logo" />
      </div>
      <v-spacer></v-spacer>
      <!-- <span>{{imgId}}</span> -->
      <v-spacer></v-spacer>
      <v-btn icon @click="closelModal">
        <v-icon color="white">{{mdiClose}}</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pa-0">
      <v-carousel
        v-model="selectedItem"
        :height="`${(height-4)}vh`"
        show-arrows-on-hover
        hide-delimiters
      >
        <v-carousel-item v-for="(item, i) in carousel_items" :key="i">
          <v-sheet>
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="pa-0">
                <div :style="`height:${(height-8)}vh`">
                  <img
                    :style="`width:100%; height:${(height-8)}vh`"
                    :src="`${$tools.getEnv('VUE_APP_FILE_SERVER')}${item.path}/${item.name}`"
                  />
                </div>
                <div class="secondary white--text" style="height:4vh; padding-top:3px;">
                  <v-btn small tile class="warning square pa-2 font-weight-bold">Before</v-btn>
                  <span class="ml-2">{{item.name}}</span>
                  <v-icon class="float-right ma-1 mr-3" color="white" @click="downloadFile(item)">{{mdiDownload}}</v-icon>
                </div>
              </v-col>
              <v-col cols="6" class="pa-0">
                <div :style="`height:${(height-8)}vh`">
                  <img
                    :style="`width:100%; height:${(height-8)}vh`"
                    :src="getDeliveredItem(item)"
                    @click="openEditor(item.delivered[item.revision])"
                  />
                </div>
                <div class="secondary white--text" style="height:4vh; padding-top:3px;">
                  <v-btn small tile class="primary square pa-1 font-weight-bold">After</v-btn>
                  <span class="ml-2">{{item.hasOwnProperty("delivered")?item.delivered[item.revision].name:'N/A'}}</span>
                  <div class="primary float-right px-1 pa-1">
                    <v-icon
                      v-if="item.hasOwnProperty('delivered')"
                      @click="openEditor(item.delivered[item.revision])"
                      small class="ma-1" color="white">
                      {{ (status == 'delivered' && isClient ? mdiPencil : mdiMagnifyPlusOutline) }}
                    </v-icon>
                    <v-icon
                      v-if="!isClient && status!='delivered'"
                      @click="uploadFile(item)"
                      small class="ma-1" color="error">
                      {{mdiUpload}}
                    </v-icon>
                    <v-icon
                      v-if="item.hasOwnProperty('delivered')"
                      @click="downloadFile(item.delivered[item.revision])"
                      small class="ma-1" color="warning">
                      {{mdiDownload}}
                    </v-icon>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-card-text>

    <!-- image upload -->
    <v-dialog v-model="uploadModal" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          Upload as per image 
        </v-card-title>
        <v-card-text class="pt-2">
          <div class="error--text pb-4 text-center">Use FTP if file size > 10MB</div>
          <div>
            <v-btn small :disabled="isBrowsed" color="primary" @click="$refs.uploader.click()">
              BROWSE
            </v-btn>
            <input ref="uploader" class="d-none" type="file" accept="image/*"
              @change="selectFile($event.target.files)"
            >
            <v-btn small text color="error" class="float-right" @click="onCancel()">
              Close
            </v-btn>
          </div>
          <div>
            <v-textarea disabled auto-grow outlined hide-details class="my-5" rows="1" :value="filetxt"></v-textarea>
            <v-btn small color="warning" :disabled="!onUpload" :loading="loadingBtn" @click.stop="uploadviaweb">
              Upload
              <v-icon right dark>{{mdiUpload}}</v-icon>
            </v-btn>
            <span v-if="loadingBtn" class="ml-3">
              <b class="error--text">Uploading:</b> {{ fileName }}
            </span>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- image upload -->

    <!-- image annotation -->
    <v-dialog v-model="anotationModal" fullscreen hide-overlay>
      <annotation
        :uuid="uuid"
        :imgId="imgId"
        :imgSrc="imgSrc"
        :annotations="annotations"
        :annoLib="anno"
        :isClient="isClient"
        @openAnnotorius="closeAnnotorius"
      />
    </v-dialog>
  </v-card>
</template>


<script>
import { mdiClose, mdiPencil, mdiMagnifyPlusOutline, mdiDownload, mdiUpload } from '@mdi/js'
import { mapActions } from "vuex"
import { SAVE_CONTENT, DOWNLOAD_FROM_URL, ADD_ALERT} from "@/store/_actiontypes"
import Annotation from "@/components/overview/annotation.vue"

export default {
  components: {
    Annotation,
  },

  props: {
    items: {
      type: Array,
      default(){
        return []
      }
    },
    status: {
      type: String,
      default: "requested",
    },
    isClient: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 100
    },
    contentPath: {
      type: String,
      default: "/contents/local/1",
    },
  },

  watch: {
    selected(newVal){
      this.selectedItem = newVal;
    },
    items(newVal){
      this.carousel_items = newVal;
    },
    contentPath(newVal){
      this.content_path = newVal;
    }
  },

  data(){
    return {
      mdiPencil,
      mdiClose,
      mdiMagnifyPlusOutline,
      mdiDownload,
      mdiUpload,
      carousel_items: this.items,
      selectedItem: this.selected,
      editMode: 1,
      fileName: "",
      filetxt: "",
      save_to: "local",
      content_type: "quotation",
      loadingBtn: false,
      isBrowsed: false,
      onUpload: false,
      progressInfos: [],
      content_path: this.contentPath,
      uploadModal: false,
      // image annotation editor
      uuid: null,
      anno: null,
      imgId: null,
      imgSrc: null,
      annotations: [],
      anotationModal: false,
    };
  },

  methods: {
    ...mapActions("upload", [SAVE_CONTENT, DOWNLOAD_FROM_URL]),

    getDeliveredItem(item){
      var deliveredItem = require('@/assets/images/misc/no_img_available.jpg');
      if(item.hasOwnProperty("delivered")){
        let itemdata = item['delivered'][item.revision]
        deliveredItem = this.$tools.getEnv('VUE_APP_FILE_SERVER') + itemdata.path +"/"+ itemdata.name;
      }
      return deliveredItem;
    },

    uploadFile(item){
      this.save_to = item.name;
      this.uploadModal = true;
    },
    selectFile(files){
      if(files.length > 0){
        let size = this.$tools.getFileSize(files[0].size);
        this.filetxt = files[0].name + ` (${size})\n`;

        this.isBrowsed = true;
        this.onUpload = true;
      }
    },
    uploadviaweb(){
      let file = this.$refs.uploader.files;
      if(file[0].name.split('.').slice(0, -1).join('.') != this.save_to.split('.').slice(0, -1).join('.')){
        alert("The name of the uploaded file did not match the name of the original file.");
        
        this.filetxt = "";
        this.onUpload = false;
        this.isBrowsed = false;
        this.ftp_dialog = false;
        return;
      }
      this.loadingBtn = true;
      this.fileName = this.getImageName(file[0].name);
      this.progressInfos = { percentage: 0, fileName: file[0].name };

      this.SAVE_CONTENT({
        save_to: this.save_to,
        path: "/quotations/delivered" + this.content_path,
        content_type: this.content_type,
        formData: file[0],
        onUploadProgress: (event) => {
          this.progressInfos.percentage = Math.round((100 * event.loaded) / event.total);
        },
      }).then((res) => {
        if(res.status==201){
          this.loadingBtn = false;
          this.$emit("reFreshImgList", res.data.content);

          this.$store.dispatch(`alert/${ADD_ALERT}`,
            {
              message: res.data.message,
              color: "success",
            },
            { root: true }
          );
          this.onCancel();
        }
      }).catch(() => {
        this.onCancel();
        this.progressInfos.percentage = 0;
      });
    },
    onCancel(){
      this.filetxt = "";
      this.uploadModal = false;
      this.onUpload = false;
      this.isBrowsed = false;
      this.loadingBtn = false;
    },
    downloadFile(item){
      let path = item.path +"/"+ item.name;
      let title = item.name;
      this.DOWNLOAD_FROM_URL({path}).then((res) => {
        this.$tools.downloadFileFromBase64(res, title)
      }).catch(()=>{});
    },

    // image annotation editor
    openEditor(item, mode=0){
      console.log("item")
      this.uuid = this.$route.query.uuid;
      this.imgId = item.name;
      this.imgSrc = this.$tools.getEnv('VUE_APP_FILE_SERVER') + item.path +'/'+ item.name
      this.annotations = item.hasOwnProperty('revision') ? item.revision : [];
      this.getAnnotations();

      if(this.anno != null){
        this.anno.setAnnotations(this.annotations);
      }
      this.anotationModal = true;
    },

    closeAnnotorius(anno=null){
      this.anno = anno;
      this.anotationModal = false;
      if(anno !== null){
        this.setAnnotations(anno.getAnnotations());
      }
    },
    getAnnotations(){
      var data = JSON.parse(localStorage.getItem(this.uuid) || '[]');
      if(data[this.imgId]){
        var anno_data = []
        for(let i = 0; i < data[this.imgId].length; i++){
          if(data[this.imgId][i] !== null && typeof data[this.imgId][i] === 'object'){
            anno_data.push(data[this.imgId][i])
          }
        }
        this.annotations = anno_data;
      } else{
        this.setAnnotations(this.annotations);
      }
    },
    setAnnotations(annotations){
      var data = JSON.parse(localStorage.getItem(this.uuid) || '{}');
      data[this.imgId] = annotations;
      localStorage.setItem(this.uuid, JSON.stringify(data));
      this.getAnnotations();
    },

    closelModal(){
      this.$emit('closelCarousel', true)
    },

  },
};
</script>
