<template>
  <div>
    <v-row class="mt-3">
      <v-col v-if="list.length>0" cols="12" sm="12">
        <!-- Image galary viewer -->
        <v-row no-gutters class="gray-border px-2 py-2 mb-1 rounded">
          <v-col cols="12" class="text-right">
            <!-- upload via ftp modal -->
            <v-dialog
              v-if="!isClient && ['processing','revision'].includes(status)"
              v-model="ftp_dialog" persistent max-width="450"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn small class="warning mx-2 rounded-pill px-5" v-on="on" v-bind="attrs">
                  UPLOAD VIA FTP
                </v-btn>
              </template>
              <v-card>
                <v-card-text class="text-center">
                  <h3 class="primary--text pa-4">UPLOAD VIA FTP</h3>
                  <p>Server / Host <br> ftp://<span class="error--text">{{ ftp_host }}</span></p>
                  <p>Use your userId and password to login to the server.<br>Upload images to this directory: <br> 
                    <span class="error--text">/{{ client_path + content_path + "/delivered/" + revision }}/</span>
                  </p>
                  <p class="warning--text">Please make sure that the names of your uploaded files and the original files are the same.</p>
                  <p><b>Please note:</b> After uploading the images via FTP press the <span class="primary--text">REFRESH</span> button below.</p>
                </v-card-text>
                <v-card-actions class="text-center d-block">
                  <v-btn small text color="error" class="rounded-pill px-5" @click="ftp_dialog=false">
                    Close
                  </v-btn>
                  <v-btn small class="primary rounded-pill px-5" @click="refreshFtpUpload()">
                    REFRESH
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- download -->
            <!-- <template v-if="status=='delivered' && isClient">
              <v-btn v-if="download_link==null" small class="warning mx-2 rounded-pill px-5" @click="downloadAsZip">
                COMPRESS ALL TO DOWNLOAD
              </v-btn>
              <v-btn v-else :href="download_link" small class="error mx-2 rounded-pill px-5" target="_blank">Download</v-btn>
            </template> -->
          </v-col>
          <template v-for="(item, index) in historyList">
            <v-col :key="index" cols="6" sm="2" class="pa-2">
              <v-card outlined tile :class="`pa-0 overflow-hidden ${isUploaded(item)?'is-uploaded':''}`">
                <v-skeleton-loader v-if="isLoading" type="image" class="image-style"></v-skeleton-loader>
                <div v-else>
                  <v-card-text class="text--primary text-center pa-0">
                    <v-tooltip top v-if="status!='revision' && item.instruction" color="primary">
                      <template v-slot:activator="{ on }">
                        <img v-on="on"
                          class="image-style"
                          :src="`${$tools.getEnv('VUE_APP_FILE_SERVER')}${item.path}/${item.name}`"
                          @click="openCarousel(index)"
                        />
                      </template>
                      <span>{{item.instruction}}</span>
                    </v-tooltip>
                    <v-tooltip top color="error" v-else-if="
                      status=='revision'&&item.hasOwnProperty('revision')&&item.revision!==revision.toString()">
                      <template v-slot:activator="{ on }">
                        <img v-on="on"
                          class="image-style"
                          :src="`${$tools.getEnv('VUE_APP_FILE_SERVER')}${item.path}/${item.name}`"
                          @click="openCarousel(index)"
                        />
                      </template>
                      <span>{{ getAnnotationMsg(item.delivered[item.revision]) }}</span>
                    </v-tooltip>
                    <img v-else
                      class="image-style"
                      :src="`${$tools.getEnv('VUE_APP_FILE_SERVER')}${item.path}/${item.name}`"
                      @click="openCarousel(index)"
                    />
                  </v-card-text>

                  <v-card-actions class="action-box d-block pa-1">
                    <span style="font-size:11px;">{{getImageName(item.name)}}</span>
                    <span v-if="step_stat=='processing' || step_stat=='delivered'" class="float-right">
                      <v-icon v-if="item.hasOwnProperty('delivered')" @click="openEditor(item.delivered[item.revision])" small color="primary">
                        {{ (status == 'delivered' && isClient ? mdiPencil : mdiMagnifyPlusOutline) }}
                      </v-icon>
                      <v-tooltip v-if="item.instruction&&item.instruction!=null" top color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small color="secondary" v-bind="attrs" v-on="on">{{mdiNotebookCheck}}</v-icon>
                        </template>
                        <span>{{item.instruction}}</span>
                      </v-tooltip>
                      <v-icon v-if="status!='delivered' && isDeliver" small color="warning" @click="uploadFile(item)">
                        {{mdiUpload}}
                      </v-icon>
                    </span>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-responsive
              v-if="index === 5"
              :key="`width-${index}`"
              width="100%"
              class="pb-1"
            ></v-responsive>
          </template>
        </v-row>

        <v-row class="ma-0">
          <!-- image delivered count status -->
          <v-col cols="12" md="3" class="px-0">
            <span class="primary--text float-left font-weight-bold">Entire Images</span>
            <div class="float-right ml-2">
              <v-chip v-if="contents.processing.length == countDeliverItems()" outlined small color="secondary">All Image Uploaded</v-chip>
              <v-chip v-else outlined small color="error">{{contents.processing.length - countDeliverItems()}} image missing</v-chip>
            </div>
          </v-col>
          <!-- image pagination -->
          <v-col cols="12" md="9" class="px-0">
            <v-pagination
              class="pagination float-right"
              v-model="page"
              :length="pages"
              :showCaption="true"
              @input="updatePage">
            </v-pagination>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <!-- upload modal -->
    <v-dialog v-model="uploadModal" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          Upload as per image 
        </v-card-title>
        <v-card-text class="pt-2">
          <div class="error--text pb-4 text-center">Use FTP if file size > 10MB</div>
          <div>
            <v-btn small :disabled="isBrowsed" color="primary" @click="$refs.uploader.click()">
              BROWSE
            </v-btn>
            <input ref="uploader" class="d-none" type="file" accept="image/*"
              @change="selectFile"
            >
            <v-btn small text color="error" class="float-right" @click="onCancel()">
              Close
            </v-btn>
          </div>
          <div>
            <v-textarea disabled auto-grow outlined hide-details class="my-5" rows="1" :value="filetxt"></v-textarea>
            <v-btn small color="warning" :disabled="!onUpload" :loading="loadingBtn" @click.stop="uploadviaweb">
              Upload
              <v-icon right dark>{{mdiCloudUpload}}</v-icon>
            </v-btn>
            <span v-if="loadingBtn" class="ml-3">
              <b class="error--text">Uploading:</b> {{ fileName }}
            </span>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- carousel modal -->
    <v-dialog v-model="carouselModal" fullscreen hide-overlay>
      <carousel
        :height="95.4"
        :items="list"
        :status="status"
        :isClient="isClient"
        :contentPath="content_path"
        :selected="selectedCarousel"
        @reFreshImgList="reFreshImgList"
        @closelCarousel="closelCarousel"
      />
    </v-dialog>
    <!-- image annotation -->
    <v-dialog v-model="anotationModal" fullscreen hide-overlay>
      <annotation
        :uuid="uuid"
        :imgId="imgId"
        :imgSrc="imgSrc"
        :annotations="annotations"
        :annoLib="anno"
        :isClient="isClient"
        @openAnnotorius="closeAnnotorius"
      />
    </v-dialog>

  </div>
</template>

<script>
import { mapActions } from "vuex"
import { mdiCloudUpload, mdiDownload, mdiUpload, mdiClose, mdiPencil, mdiNotebookCheck, mdiMagnifyPlusOutline } from '@mdi/js'
import { SAVE_CONTENT, DELIVER_BY_FTP, DOWNLOAD_FROM_URL, DOWNLOAD_AS_ZIP, ADD_ALERT} from "@/store/_actiontypes"
import Carousel from '@/components/common/Carousel'
import Annotation from "@/components/overview/annotation.vue"

export default {
  components: {
    Carousel,
    // ImageAnotation,
    Annotation
  },
  props: {
    isProcess: {
      type: Boolean,
      default: false
    },
    isDeliver: {
      type: Boolean,
      default: false
    },
    isClient: {
      type: Boolean,
      default: false
    },
    step_stat: {
      type: String,
      default: "requested",
    },
    status: {
      type: String,
      default: "requested",
    },
    revision: {
      type: Number,
      default: 1,
    },
    userId: {
      type: String,
      default: "",
    },
    jobId: {
      type: Number,
      default: 1,
    },
    method: {
      type: Number,
      default: 1,
    },
    contents: {
      type: Object,
      default(){
        return {}
      }
    },
    reviewed: {
      type: Number,
      default: 0,
    },
  },
  data(props){
    return {
      mdiUpload,
      mdiDownload,
      mdiCloudUpload,
      mdiClose,
      mdiPencil,
      mdiNotebookCheck,
      mdiMagnifyPlusOutline,
      isUploadMore: false,
      loadingBtn: false,
      isLoading: false,
      isBrowsed: false,
      onUpload: false,
      progressInfos: [],
      deleteFile: {},
      revisionedItems: {},
      selectedCarousel: 0,
      carouselModal: false,
      showModal: false,
      uploadModal: false,
      ftp_dialog: false,
      ftp_host: "23.227.167.229",
      fileName: "",
      filetxt: "",
      page: 1,
			pageSize: 12,
			listCount: 0,
      historyKey: 0,
			historyList: [],
			instruction: "",
      media: [],
      save_to: "local",
      content_type: "quotation",
      upload_method: props.method,
      download_link: null,
      content_path: `/${props.jobId}`,
      client_path: props.userId.replaceAll('-',''),
      list: this.setContent(props.contents),
      // image annotation editor
      uuid: null,
      anno: null,
      imgId: null,
      imgSrc: null,
      annotations: [],
      anotationModal: false,
    }
  },
	computed: {
		pages(){
			if (this.pageSize == null || this.listCount == null) return 0;
			return Math.ceil(this.listCount / this.pageSize);
		}
	},
	created(){
    this.ftp_host = this.$tools.getEnv('VUE_APP_FTP_SERVER');

    this.initPage();
    this.updatePage(this.page);
	},
  methods: {
    ...mapActions("alert", [ADD_ALERT]),
    ...mapActions("quotation", [DELIVER_BY_FTP]),
    ...mapActions("upload", [SAVE_CONTENT, DOWNLOAD_FROM_URL, DOWNLOAD_AS_ZIP]),

    setContent(contents){
      let data = [];
      if(contents.hasOwnProperty(this.step_stat)){
        data = contents[this.step_stat];
      }
      return data;
    },
		initPage(){
			this.listCount = this.list.length;
			if(this.listCount < this.pageSize){
				this.historyList = this.list;
			} else{
				this.historyList = this.list.slice(0, this.pageSize);
			}
		},
		updatePage(pageIndex){
			this.page = pageIndex;
			let _start = (pageIndex - 1) * this.pageSize;
			let _end = pageIndex * this.pageSize;
			this.historyList = this.list.slice(_start, _end);

      let baseurl = this.$tools.getEnv('VUE_APP_FILE_SERVER');
      let media = [];
      this.historyList.forEach(item => {
        let media_obj = {}
        media_obj["type"] = "image";
        media_obj["caption"] = item.name;
        media_obj["thumb"] = `${baseurl}${item.path}/${item.name}`;
        media_obj["src"] = `${baseurl}${item.path}/${item.name}`;
        media.push(media_obj);
      });
      this.media = media;
		},
    
    openCarousel(index){
      index = (this.pageSize * this.page) - (this.pageSize - index)
      this.selectedCarousel = index;
      this.carouselModal = true;
    },
    closelCarousel(){
      this.selectedCarousel = 0;
      this.carouselModal = false;
      this.$emit('setAnnotations', true);
    },

    uploadFile(item){
      this.save_to = item.name;
      this.uploadModal = true;
    },
    selectFile(){
      const files = this.$refs.uploader.files;
      if(files.length > 0){
        this.isBrowsed = true;
        let size = this.$tools.getFileSize(files[0].size);
        var filetxt = files[0].name + ` (${size})\n`;

        this.onUpload = true;
        this.filetxt = filetxt;
      }
    },
    uploadviaweb(){
      let file = this.$refs.uploader.files;
      if(file[0].name.split('.').slice(0, -1).join('.') != this.save_to.split('.').slice(0, -1).join('.')){
        alert("The name of the uploaded file did not match the name of the original file.");
        
        this.filetxt = "";
        this.onUpload = false;
        this.isBrowsed = false;
        this.ftp_dialog = false;
        return;
      }
      this.loadingBtn = true;
      this.fileName = this.getImageName(file[0].name);
      this.progressInfos = { percentage: 0, fileName: file[0].name };

      this.SAVE_CONTENT({
        save_to: this.save_to,
        path: "/quotations/delivered" + this.content_path,
        content_type: this.content_type,
        formData: file[0],
        onUploadProgress: (event) => {
          this.progressInfos.percentage = Math.round((100 * event.loaded) / event.total);
        },
      }).then((res) => {
        if(res.status==201){
          this.loadingBtn = false;
          let data = res.data.content;
          if(data.hasOwnProperty(this.step_stat)){
            this.list = data[this.step_stat];
          }
          this.$emit("deliveredItems", data);

          this.$store.dispatch(`alert/${ADD_ALERT}`,
            {
              message: res.data.message,
              color: "success",
            },
            { root: true }
          );
          this.onCancel();
          this.initPage();
          this.countDeliverItems();
          this.updatePage(this.page);
        }
      }).catch(() => {
        this.onCancel();
        this.progressInfos.percentage = 0;
      });
    },
    onCancel(){
      this.$refs.uploader.value= null;
      this.filetxt = "";
      this.uploadModal = false;
      this.onUpload = false;
      this.isBrowsed = false;
      this.ftp_dialog = false;
      this.loadingBtn = false;
    },
    downloadFile(item){
      let path = item.path +"/"+ item.name;
      let title = item.name;
      this.DOWNLOAD_FROM_URL({path}).then((res) => {
        this.$tools.downloadFileFromBase64(res, title)
      }).catch(()=>{});
    },

    getImageName(name){
      if(this.isClient && (this.status=='requested'||this.status=='created')){
        name = name.substring(0, 9) + "..";
      } else if(name.length <= 15){
        name = name
      } else{
        name = name.substring(0, 13) + "..";
      }
      return name;
    },

    countDeliverItems(){
      let count = 0;
      for (let i = 0; i < this.list.length; i++) {
        let data = this.list[i];
        if(data['delivered']){
          if(data.revision && this.status == 'revision'){
            let delivered = data["delivered"][data.revision]
            if(delivered['revision'] && data.revision !== this.revision.toString()){
              count += 1;
            }
          } else{
            count += 1;
          }
        } else if(this.status == 'revision'){
          count += 1;
        }
      }
      return (this.status=='revision') ? this.list.length-count : count;
    },

    isUploaded(data){
      let uploaded = false
      if(data['delivered']){
        if(this.status == 'revision' && data.revision != this.revision.toString()){
          uploaded = true
          if(data['delivered'][data.revision]['revision']){
            uploaded = false
          }
        } else{
          uploaded = true
        }
      }
      return uploaded
    },

    reFreshImgList(data){
      this.list = this.setContent(data);
      this.initPage();
      this.updatePage(this.page);
    },

    getAnnotationMsg(items){
      let msg = "";
      if(items['revision']){
        for(let i = 0; i < items['revision'].length; i++){
          items['revision'][i]['body'].forEach(item => {
            msg += item['value'] + ", ";
          });
        }
      }
      return msg.replace(/, $/,"")
    },
    // image annotation editor
    openEditor(item, mode=0){
      this.uuid = this.$route.query.uuid;
      this.imgId = item.name;
      this.imgSrc = this.$tools.getEnv('VUE_APP_FILE_SERVER') + item.path +'/'+ item.name
      this.annotations = item.hasOwnProperty('revision') ? item.revision : [];
      this.getAnnotations();

      if(this.anno != null){
        this.anno.setAnnotations(this.annotations);
      }
      this.anotationModal = true;
    },
    closeAnnotorius(anno){
      this.anno = anno;
      this.anotationModal = false;
      this.setAnnotations(anno.getAnnotations());
      this.$emit('setAnnotations', true);
    },
    getAnnotations(){
      var data = JSON.parse(localStorage.getItem(this.uuid) || '[]');
      if(data[this.imgId]){
        var anno_data = []
        for(let i = 0; i < data[this.imgId].length; i++){
          if(data[this.imgId][i] !== null && typeof data[this.imgId][i] === 'object'){
            anno_data.push(data[this.imgId][i])
          }
        }
        this.annotations = anno_data;
      } else{
        this.setAnnotations(this.annotations);
      }
    },
    setAnnotations(annotations){
      var data = JSON.parse(localStorage.getItem(this.uuid) || '{}');
      data[this.imgId] = annotations;
      localStorage.setItem(this.uuid, JSON.stringify(data));
      this.getAnnotations();
    },

    // refresh image list after uploading via ftp
    refreshFtpUpload(){
      this.DELIVER_BY_FTP({ job_id: this.jobId }).then((res) => {
        if(res.status==201){
          let data = res.data.content;
          if(data.hasOwnProperty(this.step_stat)){
            this.list = data[this.step_stat];
          }
          this.$emit("deliveredItems", data);

          this.$store.dispatch(`alert/${ADD_ALERT}`,
            {
              message: res.data.message,
              color: "success",
            },
            { root: true }
          );

          this.onCancel();
          this.initPage();
          this.countDeliverItems();
          this.updatePage(this.page);
        }
        if(res.status==203){
          this.$store.dispatch(`alert/${ADD_ALERT}`,
            {
              message: res.data.message,
              color: "success",
            },
            { root: true }
          );
        }
      }).catch(() => {});
    },
    // compress files as zip and getting download url
    downloadAsZip(){
      this.download_link = null;
      this.DOWNLOAD_AS_ZIP({job_id:this.jobId, status:this.status}).then((res) => {
        if(res.status==200){
          let file_server = this.$tools.getEnv('VUE_APP_FILE_SERVER');
          this.download_link = res.data.path!=null ? file_server+res.data.path : null;
        }
      }).catch(()=>{
        this.download_link = null;
      });
    },

  }
}
</script>

<style scoped>
.gray-border{
  border:1px solid gray;
}
.action-box{
  height: 25px;
  font-size: 12px;
  background: #fafafa;
}
.image-style{
  width:143px;
  height:143px;
  margin-bottom:-5px;
}
.image-style:hover{
  cursor: pointer;
}
.image-opacity {
  opacity: 0.5;
}
.is-uploaded{
  box-shadow: 3px 3px 2px 0px rgb(100 100 100 / 80%);
}
</style>